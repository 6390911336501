import React from 'react';

const Character = () => {
  return (
    <div 
      className="absolute top-2/4 left-1/2 transform -translate-x-1/2 w-48 h-72 bg-contain bg-no-repeat"
      style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/dino.png')` }}
    ></div>
  );
};

export default Character;