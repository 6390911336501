import React from 'react';
import './DisclaimerModal.css';

const DisclaimerModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Disclaimer</h2>
        <p className="modal-text">
          This website and the Brachio Token are for entertainment purposes only. Cryptocurrency investments carry high risk, and the value can fluctuate significantly. Never invest more than you can afford to lose. This is not financial advice, and you should always do your own research before making any investment decisions.
        </p>
        <p className="modal-text">
          Remember: The crypto market is highly volatile and unregulated. Participate at your own risk!
        </p>
        <button className="modal-close-btn" onClick={onClose}>I Understand</button>
      </div>
    </div>
  );
};

export default DisclaimerModal;