import React, { useState, useEffect } from 'react';
import './PulsingToken.css';
import DisclaimerModal from './DisclaimerModal';

const PulsingToken = () => {
  const [tokenSize, setTokenSize] = useState(64);
  const [rightPosition, setRightPosition] = useState(8);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setTokenSize(200);
        setRightPosition(8);
      } else if (width < 1024) {
        setTokenSize(106);
        setRightPosition(16);
      } else {
        setTokenSize(400);
        setRightPosition(16);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTokenClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div 
        className="pulsing-token cursor-pointer"
        style={{
          width: `${tokenSize}px`,
          height: `${tokenSize}px`,
          right: `${rightPosition}px`,
        }}
        onClick={handleTokenClick}
      >
        <img 
          src={`${process.env.PUBLIC_URL}/unofficialtoken.png`}
          alt="Unofficial Token"
          className="token-image"
        />
      </div>
      {isModalOpen && <DisclaimerModal onClose={() => setIsModalOpen(false)} />}
    </>
  );
};

export default PulsingToken;