import React, { useState } from 'react';
import ReactDOM from 'react-dom';

const GameButton = () => {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openIframe = () => setIsIframeOpen(true);
  const closeIframe = () => {
    setIsIframeOpen(false);
    setIsLoading(true);
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      <button 
        onClick={openIframe}
        className="
          bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg
          transition-all duration-300 ease-in-out
          animate-shake-n-hop hover:animate-none
        "
      >
        Play Game
      </button>

      {isIframeOpen && ReactDOM.createPortal(
        <div className="fixed inset-0 z-50 bg-black flex justify-center items-center">
          <div className="relative w-full h-full">
            <button 
              onClick={closeIframe}
              className="absolute top-4 right-4 text-white text-4xl z-10 hover:text-gray-300"
            >
              &times;
            </button>
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-white text-2xl">Loading...</p>
              </div>
            )}
            <iframe 
              src="https://game.jogumanstudio.com/" 
              className="w-full h-full"
              title="Joguman Studio Game"
              allow="autoplay"
              sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
              onLoad={handleIframeLoad}
            ></iframe>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default GameButton;