import React from 'react';
import Character from './Character';
import Cloud from './Cloud';
import GameButton from './GameButton';
import PulsingToken from './PulsingToken';

const MainScene = () => {
  return (
    <main className="relative h-[89vh] overflow-hidden bg-sky-300">
      {/* Grass background */}
      <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-grass-green"></div>
      
      {/* Grass edge image */}
      <div 
        className="absolute bottom-1/3 left-0 right-0 h-8 bg-repeat-x"
        style={{ 
          backgroundImage: `url(${process.env.PUBLIC_URL}/grass.png)`,
          backgroundSize: 'auto 100%'
        }}
      ></div>
      
      {/* Character */}
      <Character />

      {/* Game Button */}
      <div className="absolute bottom-12 left-1/2 transform -translate-x-1/2">
        <GameButton />
      </div>
      
      {/* Clouds */}
      <Cloud className="top-10 left-10" />
      <Cloud className="top-1/4 right-1/4" />
      <Cloud className="bottom-1/2 left-1/3" />
      <Cloud className="top-1/4 right-20" />

      {/* Pulsing Token */}
      <PulsingToken />
    </main>
  );
};

export default MainScene;