import React from 'react';

const Cloud = ({ className }) => {
  return (
    <div className={`absolute ${className}`}>
      <img 
        src={`${process.env.PUBLIC_URL}/cloud.png`} 
        alt="Cloud"
        className="w-30 h-auto" // Adjust size as needed
      />
    </div>
  );
};

export default Cloud;