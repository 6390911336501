import React from 'react';
import Header from './components/Header';
import MainScene from './components/MainScene';
import ContractAddress from './components/ContractAddress';

function App() {
  return (
    <div className="min-h-screen bg-sky-blue">
      <Header />
      <ContractAddress />
      <MainScene />
      
    </div>
  );
}

export default App;