import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const links = [
    { name: 'DexScreener', image: 'dexscreener.png', url: 'https://dexscreener.com/solana/58qas7ghzs5dtagmd4a2f7qzvmxhe38uzy7ar7uu1aqp', scale: 2.2 },
    { name: 'Telegram', image: 'telegramlogo.png', url: 'https://t.me/brachiosol', scale: 1 },
    { name: 'Raydium', image: 'raydium-removebg-preview.png', url: 'https://raydium.io/swap/?inputMint=sol&outputMint=A11P4YzikysrP8KAwhiT2xTuGMHpH6i9oHSZnntapump', scale: 1 },
  ];

  const renderIcon = (link) => (
    <img 
      src={`${process.env.PUBLIC_URL}/${link.image}`} 
      alt={link.name}
      className="w-6 h-6 md:w-8 md:h-8 object-contain"
      style={{ transform: `scale(${link.scale})` }}
    />
  );

  
  return (
    <header className="p-2 md:p-4">
      <nav className="flex justify-between items-center relative">
        <div className="flex-shrink-0 absolute left-0">
          <img 
            src={`${process.env.PUBLIC_URL}/image.png`} 
            alt="Brachio Token" 
            className="h-12 md:h-16 w-auto mt-4"
          />
        </div>
        
        {/* Mobile hamburger button */}
        <button 
          className="md:hidden z-20 absolute right-0 top-6"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          ☰
        </button>
        
        {/* Mobile and Desktop menu */}
        <ul className={`
          ${isMenuOpen ? 'flex' : 'hidden'} 
          md:flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 
          items-center justify-center
          w-full md:static absolute top-16 md:top-auto 
          bg-white md:bg-transparent p-4 md:p-0 
          rounded shadow-lg md:shadow-none z-10
        `}>
          {links.map((link) => (
            <li key={link.name}>
              <a href={link.url} className="block">
                {renderIcon(link)}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;