import React, { useState, useEffect } from 'react';

const ContractAddress = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [copied, setCopied] = useState(false);
  const fullAddress = 'A11P4YzikysrP8KAwhiT2xTuGMHpH6i9oHSZnntapump';
  const abbreviatedAddress = `${fullAddress.slice(0, 6)}...${fullAddress.slice(-4)}`;

  useEffect(() => {
    const checkIfMobile = () => setIsMobile(window.innerWidth < 768);
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(fullAddress).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="text-center py-3 bg-sky-300">
      <p className="font-cooper-black text-lg font-bold text-black mt-3">
        Contract Address: {' '}
        <span 
          className="font-mono px-2 py-1 rounded cursor-pointer hover:bg-sky-400 transition-colors duration-300"
          onClick={copyToClipboard}
        >
          {isMobile ? abbreviatedAddress : fullAddress}
        </span>
        {copied && <span className="ml-2 text-green-600">Copied!</span>}
      </p>
    </div>
  );
};

export default ContractAddress;